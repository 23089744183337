import Logo from "../assets/Logo.jpg";
import { Link } from "react-router-dom";
import { useState } from "react";
const Title = () => (
    <a href="/">
      <img
        className="w-28 flex-wrap mx-10"
        alt="Boltavenue"
        src={ Logo }
      />
    </a>
  );
const Header = () => {

  const [isOpen, setIsOpen] = useState(false);

    return (
      <>
        <div className="flex flex-wrap shadow justify-between pr-4 mx-0">
          <Title />
          <div className="">
            





          <div className="block lg:hidden">
       <button
         onClick={() => setIsOpen(!isOpen)}
         className="flex items-center px-3 py-8  rounded text-black-500 hover:text-black-400"
       >
         <svg
           className={`fill-current h-6 w-6 ${isOpen ? "hidden" : "block"}`}
           viewBox="0 0 20 20"
           xmlns="http://www.w3.org/2000/svg"
         >
           <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
         </svg>
         <svg
           className={`fill-current h-6 w-6 ${isOpen ? "block" : "hidden"}`}
           viewBox="0 0 20 20"
           xmlns="http://www.w3.org/2000/svg"
         >
           <path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z" />
         </svg>
       </button>
     </div>




<div className={`w-full block flex-grow lg:flex lg:items-center lg:w-auto ${isOpen ? "block" : "hidden"}`} >

            <ul className=" text-sm lg:flex-grow justify-end mx-2 pt-8 px-2 w-full">
              <li className="block mt-4 lg:inline-block lg:mt-0 text-white-200 mr-4 pr-4 "><Link to="/">Home</Link></li>
              <li className="block mt-4 lg:inline-block lg:mt-0 text-white-200 mr-4 pr-4"><Link to="/about">About us</Link></li>
              <li className="block mt-4 lg:inline-block lg:mt-0 text-white-200 mr-4 pr-4"><Link to="/contact">Contact</Link></li>
              <li className="block mt-4 lg:inline-block lg:mt-0 text-white-200 mr-4 pr-4"><Link to="/careers">Careers</Link></li>
            </ul>
          </div>
        </div>
        </div>
      </>
    );
  };
export default Header;
