// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  
  apiKey:`${process.env.REACT_APP_API_KEY}`,
 
  authDomain: `${process.env.REACT_APP_AUTH_DOMAIN}`,
  projectId: "boltavenue-33ae3",
  storageBucket: "boltavenue-33ae3.appspot.com",
  messagingSenderId: "998361104142",
  appId: "1:998361104142:web:e25b47b3ffaf7c85f1e15f",
  measurementId: "G-1KH0ZEDS72"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const storage = getStorage(app);